<template>
  <v-col>
    <v-col class="hidden-sm-and-down pt-0">
      <v-btn-toggle v-model="step" class="transparent" mandatory>
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="1"
          outlined
          style="width: 117px; height: 50px"
          >기본 사항</v-btn
        >
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="2"
          outlined
          style="width: 117px; height: 50px"
          >가맹점 정보</v-btn
        >
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="3"
          outlined
          style="width: 117px; height: 50px"
          >광고 정보</v-btn
        >
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="4"
          outlined
          style="width: 117px; height: 50px"
          >채널 관리</v-btn
        >
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="5"
          outlined
          style="width: 118px; height: 50px"
          >품목 정보</v-btn
        >
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="6"
          outlined
          style="width: 118px; height: 50px"
          >사진 관리</v-btn
        >
      </v-btn-toggle>
    </v-col>
    <!--</v-toolbar>-->
    <v-col class="content-page my-2 pt-0 hidden-sm-and-down">
      <Page1 v-if="step === 1" :adsData="adsData" :option="option"></Page1>
      <StorePage2
        v-if="(adsData.category || {}).firstName === '매장' && step === 2"
        :adsData="adsData"
        :option="option"
        @updateEvent="updateEvent"
      ></StorePage2>
      <ProductPage2
        v-else-if="step === 2"
        :adsData="adsData"
        :option="option"
        @updateEvent="updateEvent"
      ></ProductPage2>
      <StorePage3
        v-if="(adsData.category || {}).firstName === '매장' && step === 3"
        :adsData="adsData"
        :option="option"
        @updateEvent="updateEvent"
      ></StorePage3>
      <ProductPage3
        v-else-if="step === 3"
        :adsData="adsData"
        :option="option"
        @updateEvent="updateEvent"
      ></ProductPage3>
      <StorePage4
        v-if="(adsData.category || {}).firstName === '매장' && step === 4"
        :adsData="adsData"
        :option="option"
        @updateEvent="updateEvent"
        ref="page4"
      ></StorePage4>
      <ProductPage4
        v-else-if="step === 4"
        :adsData="adsData"
        :option="option"
        @updateEvent="updateEvent"
        ref="page4"
      ></ProductPage4>
      <StorePage5
        v-if="(adsData.category || {}).firstName === '매장' && step === 5"
        :adsData="adsData"
        :option="option"
        @updateEvent="updateEvent"
      ></StorePage5>
      <ProductPage5
        v-else-if="step === 5"
        :adsData="adsData"
        :option="option"
        @updateEvent="updateEvent"
      ></ProductPage5>
      <Page6
        v-if="step === 6"
        :adsData="adsData"
        :option="option"
        @updateEvent="updateEvent"
      ></Page6>

      <v-col class="pa-2">
        <v-btn
          v-if="step !== 1"
          block
          color="primary"
          class="ma-0"
          @click="registerAds"
        >
          저장
        </v-btn>
      </v-col>
    </v-col>
    <v-col class="hidden-md-and-up">
      <div class="line"></div>
      <v-tabs v-model="tab" color="white" show-arrows mobile-breakpoint="960">
        <v-tabs-slider color="rouge"></v-tabs-slider>
        <v-tab
          v-for="step in 6"
          :href="'#tab-' + step"
          :key="step"
          active-class="rouge--text"
          class="subheading"
        >
          <span v-if="step === 1">기본 사항</span>
          <span v-else-if="step === 2">가맹점 정보</span>
          <span v-else-if="step === 3">광고 정보</span>
          <span v-else-if="step === 4">채널 관리</span>
          <span v-else-if="step === 5">품목 정보</span>
          <span v-else-if="step === 6">사진 관리</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <div class="line"></div>
        <v-tab-item v-for="step in 6" :value="'tab-' + step" :key="step">
          <Page1 v-if="step === 1" :adsData="adsData" :option="option"></Page1>
          <StorePage2
            v-if="(adsData.category || {}).firstName === '매장' && step === 2"
            :adsData="adsData"
            :option="option"
            @updateEvent="updateEvent"
          ></StorePage2>
          <ProductPage2
            v-else-if="step === 2"
            :adsData="adsData"
            :option="option"
            @updateEvent="updateEvent"
          ></ProductPage2>
          <StorePage3
            v-if="(adsData.category || {}).firstName === '매장' && step === 3"
            :adsData="adsData"
            :option="option"
            @updateEvent="updateEvent"
          ></StorePage3>
          <ProductPage3
            v-else-if="step === 3"
            :adsData="adsData"
            :option="option"
            @updateEvent="updateEvent"
          ></ProductPage3>
          <StorePage4
            v-if="(adsData.category || {}).firstName === '매장' && step === 4"
            :adsData="adsData"
            :option="option"
            @updateEvent="updateEvent"
            ref="page4"
          ></StorePage4>
          <ProductPage4
            v-else-if="step === 4"
            :adsData="adsData"
            :option="option"
            @updateEvent="updateEvent"
            ref="page4"
          ></ProductPage4>
          <StorePage5
            v-if="(adsData.category || {}).firstName === '매장' && step === 5"
            :adsData="adsData"
            :option="option"
            @updateEvent="updateEvent"
          ></StorePage5>
          <ProductPage5
            v-else-if="step === 5"
            :adsData="adsData"
            :option="option"
            @updateEvent="updateEvent"
          ></ProductPage5>
          <Page6
            v-if="step === 6"
            :adsData="adsData"
            :option="option"
            @updateEvent="updateEvent"
          ></Page6>

          <v-btn
            v-if="step !== 1"
            block
            color="primary"
            class="ma-0"
            @click="registerAds"
          >
            저장
          </v-btn>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-col>
</template>

<script>
import userMixin from "@/components/shared/userMixin";
import companyMixin from "@/components/shared/companyMixin";

export default {
  name: "Modads",
  components: {
    Page1: () => import("@/components/company/modads/Page1"),
    StorePage2: () => import("@/components/company/modads/store/Page2"),
    StorePage3: () => import("@/components/company/modads/store/Page3"),
    StorePage4: () => import("@/components/company/modads/store/Page4"),
    StorePage5: () => import("@/components/company/modads/store/Page5"),
    ProductPage2: () => import("@/components/company/modads/product/Page2"),
    ProductPage3: () => import("@/components/company/modads/product/Page3"),
    ProductPage4: () => import("@/components/company/modads/product/Page4"),
    ProductPage5: () => import("@/components/company/modads/product/Page5"),
    Page6: () => import("@/components/company/modads/Page6"),
  },
  mixins: [userMixin, companyMixin],
  data() {
    return {
      tab: 1,
      step: 1,
      adsData: {
        category: {
          firstName: "매장",
          first: "1cbTA11dQev5s531ukil",
          secondName: "",
          thirdName: "",
        },
      },
      option: { category: {} },
    };
  },
  watch: {
    company(value) {
      this.adsData = JSON.parse(JSON.stringify(value));
    },
  },
  methods: {
    updateEvent(e) {
      // this._.merge(this.adsData, e);
      Object.keys(e).forEach((key) => {
        this.adsData[key] = e[key];
      });
      // if (e) {
      //   this.$store.commit('setDirty', true);
      // }
    },
    async save() {
      const clone = { ...this.adsData };

      //프리미엄 체험단 생성시 다른 등급 금액 초기화
      if (clone.type === 'A'){
        ['black', 'red', 'yellow'].forEach(level => {
          clone.naver[`${level}`] = null;
          clone.naver[`${level}FullPrice`] = null;
          clone.naver[`${level}Price`] = null;
          clone.naver[`${level}Payment`] = null;
        })
      }

      this.adsData = clone;

      try {
        await this.axios.put("/company3?id=" + this.adsData.id, {
          ...this.adsData,
          delay: true,
        });
        this.$store.commit("setDirty", false);
        await this.$store.dispatch("getCompanyInfo", {
          email: this.user.email,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async registerAds() {
      // if ((this.adsData.requiredKeywords || []).length === 0) {
      //   this.$store.commit('setMsg', '필수 입력 항목을 입력해주세요.');
      //   this.$store.commit('setShowSnackbar', true);
      //   return;
      // }
      if (!this.$store.getters.loading) {
        this.$store.commit("setLoading", true);

        if ((this.$refs.page4 || {}).addKeyword) {
          await this.$refs.page4.addKeyword();
        } else if (this.$refs.page4 && (this.$refs.page4 || {})[0].addKeyword) {
          await this.$refs.page4[0].addKeyword();
        }
        if ((this.$refs.page4 || {}).addHashtag) {
          await this.$refs.page4.addHashtag();
        } else if (this.$refs.page4 && (this.$refs.page4 || {})[0].addHashtag) {
          await this.$refs.page4[0].addHashtag();
        }

        try {
          ["naver", "insta"].forEach((channel) => {
            if (this.adsData[channel]) {
              if (!this.adsData[channel].revisitPeriod) {
                this.adsData[channel].revisitPeriod = this.adsData.category.firstName === "매장" ? 60 : 60;
              }
              if (this.adsData[channel].number > 0) {
                this.adsData[channel].current = 0;
              }
              if (this.adsData[channel].blackPayment === undefined) {
                this.adsData[channel].blackPayment = 0;
              }
              if (this.adsData[channel].redPayment === undefined) {
                this.adsData[channel].redPayment = 0;
              }
              if (this.adsData[channel].yellowPayment === undefined) {
                this.adsData[channel].yellowPayment = 0;
              }

              if (this.adsData[channel].whitePayment === undefined) {
                this.adsData[channel].whitePayment = 0;
              }

              if (this.adsData.category.firstName === "제품" && this.adsData[channel].deliveryCharge === undefined) {
                this.adsData[channel].deliveryCharge = 0;
              }

              if (this.adsData.type === 'S') {
                if (this.adsData[channel].black !== undefined) {
                  this.adsData[channel].diamond = this.adsData[channel].black;
                }
                if (this.adsData[channel].blackFullPrice !== undefined) {
                  this.adsData[channel].diamondFullPrice = this.adsData[channel].blackFullPrice;
                }
                if (this.adsData[channel].blackPrice !== undefined) {
                  this.adsData[channel].diamondPrice = this.adsData[channel].blackPrice;
                }
                if (this.adsData[channel].blackPayment !== undefined) {
                  this.adsData[channel].diamondPayment = this.adsData[channel].blackPayment;
                }
              }
            }
          });

          delete this.adsData.verified;
          const body = { ...this.adsData };

          /*
          rereceiptReviewRequired => false 라는 것은 영수증 리뷰가 필수가 아닌 것으로 화이트 등급이 참여가 불가능
          enableWhite => false 라는 것은 화이트 등급을 활성화 하지 않겠다는 뜻
          */
          if (!body.receiptReviewRequired || !body?.naver?.enableWhite) {
            ["white", "whiteFullPrice", "whitePayment", "whitePrice"].forEach(
              (key) => {
                body["naver"][key] = 0;
              }
            );
          }

          this.adsData = body;

          await this.save();

          this.$store.commit("setMsg", "저장이 완료되었습니다.");
          this.$store.commit("setShowSnackbar", true);
        } catch (err) {
          console.log(err);
        }
        this.$store.commit("setLoading", false);
      }
    },
  },
  created() {
    this.adsData = JSON.parse(JSON.stringify(this.company));
    this.$store.commit("setDirty", false);
  },
  beforeCreate() {
    this.axios
      .get("/company/option")
      .then((option) => {
        this.option = option.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped lang="scss">
::v-deep h3 {
  height: 24px;
  font-size: 18px;
}

::v-deep .v-btn-toggle .v-btn:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

::v-deep .v-btn-toggle .v-btn:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

::v-deep .estimate {
  border-radius: 1px;
  background-color: #fcf2f3;
  padding: 10px;
}

::v-deep .light_bulb {
  width: 14px;
  height: 20px;
  object-fit: contain;
  background-color: #00000000;
}

::v-deep .line {
  border-bottom: 1px solid #c2c2c2;
  border-bottom: 1px solid var(--pinkish-grey-color);
  width: 100%;
}
</style>
